import React, { createContext, FC, MouseEventHandler, useContext, useEffect, useMemo, useReducer } from 'react';
import { useRouter } from 'next/router';
import { Auth } from '@aws-amplify/auth';
import type { CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import type { CodeDeliveryDetailsType } from '@aws-sdk/client-cognito-identity-provider';
import type { ISignUpResult } from 'amazon-cognito-identity-js';
import type { UserAttributes } from 'features/auth/lib/enrich-user';
import { getPublicConfig as getConfig } from 'config';
import { pages } from 'features/auth/urls';
import { pages as homePages } from 'features/home/urls';
import { identify, track } from 'utils';
import dayjs from 'dayjs';
import { CognitoError } from './cognito-error-messages';
import { setUser as setLoggingUser, clearUser as clearLoggingUser } from 'logging/browser';
import logger from 'logging';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Impossible<K extends keyof any> = {
  [P in K]: never;
};

// The secret sauce! Provide it the type that contains only the properties you want,
// and then a type that extends that type, based on what the caller provided
// using generics.
type Exact<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

interface State {
  user: User | null;
  isUserInitialised: boolean;

  logInInProgress: boolean;
  logInResult: unknown;
  logInError: CognitoError | null;

  signUpResult: ISignUpResult;
  signUpInProgress: boolean;
  signUpError: CognitoError | null;

  resetPasswordRequestInProgress: boolean;
  resetPasswordRequestResult: unknown;
  resetPasswordRequestError: CognitoError | null;

  resetPasswordEmail: string;
  resetPasswordCode: string;
  resetPasswordInProgress: boolean;
  resetPasswordIsSuccess: boolean;
  resetPasswordError: CognitoError | null;

  resendSignUpResult: CodeDeliveryDetailsType;
  resendSignUpInProgress: boolean;
  resendSignUpError: CognitoError | null;

  confirmSignUpResult: unknown;
  confirmSignUpError: CognitoError | null;
}

interface Dispatchers {
  signUp(
    email: string,
    password: string,
    name: string,
    number: string,
    stringifiedCalculatorValues: string,
    utm_campaign: string,
    utm_source: string
  ): Promise<void>;
  signOut(redirectUrl?: string): void;
  confirmSignUp(id: string, code: string): void;
  logIn(email: string, password: string): void;
  resetPassword(code: string, username: string, password: string): Promise<void>;
  resetPasswordRequest(username: string): void;
  redirectToSignUp: MouseEventHandler<Element>;
  resetSignUp(): void;
  resendSignUpEmail(username: string): Promise<void>;
  resetAuthActionState(): void;
}

type CognitoAuthContextValue = Exact<State & Dispatchers>;

type FailedAction = { error: CognitoError };

type Action =
  | { type: 'RESET_AUTH_ACTION_STATE' }
  | { type: 'SET_USER'; user: User }
  | { type: 'LOGIN' }
  | { type: 'LOGIN_SUCCESS'; result: CodeDeliveryDetailsType }
  | ({ type: 'LOGIN_FAILURE' } & FailedAction)
  | { type: 'SIGNOUT' }
  | ({ type: 'SIGNOUT_FAILURE' } & FailedAction)
  | { type: 'SIGNUP' }
  | { type: 'SIGNUP_SUCCESS'; result: ISignUpResult } // ISignUpResult is not exposed
  | ({ type: 'SIGNUP_FAILURE' } & FailedAction)
  | { type: 'CONFIRM_SIGN_UP' }
  | { type: 'CONFIRM_SIGN_UP_SUCCESS'; result: unknown }
  | ({ type: 'CONFIRM_SIGN_UP_FAILURE' } & FailedAction)
  | { type: 'RESEND_SIGNUP' }
  | { type: 'RESEND_SIGNUP_SUCCESS'; result: CodeDeliveryDetailsType }
  | ({ type: 'RESEND_SIGNUP_FAILURE' } & FailedAction)
  | { type: 'RESET_SIGNUP' }
  | { type: 'RESET_PASSWORD_REQUEST' }
  | { type: 'RESET_PASSWORD_REQUEST_SUCCESS'; result: CodeDeliveryDetailsType }
  | ({ type: 'RESET_PASSWORD_REQUEST_FAILURE' } & FailedAction)
  | { type: 'RESET_PASSWORD' }
  | { type: 'RESET_PASSWORD_SUCCESS' }
  | ({ type: 'RESET_PASSWORD_FAILURE' } & FailedAction);

const {
  NEXT_PUBLIC_APP_URL,
  NEXT_PUBLIC_AWS_REGION,
  NEXT_PUBLIC_AWS_COGNITO_DOMAIN,
  NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID,
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
  NEXT_PUBLIC_PRIVACY_VERSION,
} = getConfig();

Auth.configure({
  region: NEXT_PUBLIC_AWS_REGION,
  userPoolId: NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID,

  oauth: {
    domain: NEXT_PUBLIC_AWS_COGNITO_DOMAIN,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${NEXT_PUBLIC_APP_URL}`,
    redirectSignOut: `${NEXT_PUBLIC_APP_URL}`,
    responseType: 'code',
  },
});

const initialState: State = {
  user: null,
  isUserInitialised: false,

  logInInProgress: false,
  logInResult: null,
  logInError: null,

  signUpInProgress: false,
  signUpResult: null,
  signUpError: null,

  // Request to reset password
  resetPasswordRequestInProgress: false,
  resetPasswordRequestResult: null,
  resetPasswordRequestError: null,

  // Reset password
  resetPasswordEmail: '',
  resetPasswordCode: '',
  resetPasswordInProgress: false,
  resetPasswordIsSuccess: false,
  resetPasswordError: null,

  resendSignUpInProgress: false,
  resendSignUpResult: null,
  resendSignUpError: null,

  confirmSignUpResult: null,
  confirmSignUpError: null,
};

function reducer(state: State, action: Action): State {
  if ('error' in action) {
    logger.debug({ msg: 'FailedAction %s: %O', action: action.type, error: action.error });
  }

  switch (action.type) {
    case 'RESET_AUTH_ACTION_STATE': {
      // Reset auth state except initialised user
      return {
        ...initialState,
        user: state.user,
        isUserInitialised: state.isUserInitialised,
      };
    }
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
        isUserInitialised: true,
      };
    case 'SIGNOUT':
      return {
        ...state,
        user: null,
      };
    case 'RESET_SIGNUP':
      return {
        ...state,
        signUpInProgress: false,
        signUpResult: null,
        signUpError: null,
        resendSignUpInProgress: false,
        resendSignUpResult: null,
        resendSignUpError: null,
      };
    case 'SIGNUP':
      return {
        ...state,
        signUpError: null,
        signUpInProgress: true,
        signUpResult: null,
        resendSignUpInProgress: false,
        resendSignUpResult: null,
      };
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        signUpError: null,
        signUpInProgress: false,
        signUpResult: action.result,
      };
    case 'SIGNUP_FAILURE':
      return {
        ...state,
        signUpError: action.error,
        signUpInProgress: false,
        signUpResult: null,
      };
    case 'CONFIRM_SIGN_UP':
      return {
        ...state,
        confirmSignUpError: null,
        confirmSignUpResult: null,
      };
    case 'CONFIRM_SIGN_UP_SUCCESS':
      return {
        ...state,
        confirmSignUpError: null,
        confirmSignUpResult: action.result,
      };
    case 'CONFIRM_SIGN_UP_FAILURE':
      return {
        ...state,
        confirmSignUpError: action.error,
        confirmSignUpResult: null,
      };
    case 'LOGIN':
      return {
        ...state,
        logInError: null,
        logInInProgress: true,
        logInResult: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        logInError: null,
        logInInProgress: false,
        logInResult: action.result,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        logInError: action.error,
        logInInProgress: false,
        logInResult: null,
      };
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        resetPasswordRequestError: null,
        resetPasswordRequestInProgress: true,
        resetPasswordRequestResult: null,
      };
    case 'RESET_PASSWORD_REQUEST_SUCCESS':
      return {
        ...state,
        resetPasswordRequestError: null,
        resetPasswordRequestInProgress: false,
        resetPasswordRequestResult: action.result,
      };
    case 'RESET_PASSWORD_REQUEST_FAILURE':
      return {
        ...state,
        resetPasswordRequestError: action.error,
        resetPasswordRequestInProgress: false,
        resetPasswordRequestResult: null,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordInProgress: true,
        resetPasswordIsSuccess: false,
        resetPasswordRequestResult: null,
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordInProgress: false,
        resetPasswordIsSuccess: true,
      };
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        resetPasswordError: action.error,
        resetPasswordInProgress: false,
        resetPasswordIsSuccess: false,
      };
    case 'RESEND_SIGNUP':
      return {
        ...state,
        resendSignUpError: null,
        signUpInProgress: false,
        signUpResult: null,
        resendSignUpInProgress: true,
        resendSignUpResult: null,
      };
    case 'RESEND_SIGNUP_SUCCESS':
      return {
        ...state,
        resendSignUpError: null,
        resendSignUpInProgress: false,
        resendSignUpResult: action.result,
      };
    case 'RESEND_SIGNUP_FAILURE':
      return {
        ...state,
        resendSignUpError: action.error,
        resendSignUpInProgress: false,
        resendSignUpResult: null,
      };
    default:
      return state;
  }
}

export const CognitoAuthContext = createContext<CognitoAuthContextValue>(initialState as CognitoAuthContextValue);

export const AuthProvider: FC = (props) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUser = (cognitoUser: (CognitoUser & { attributes: UserAttributes }) | null) => {
    logger.debug({ msg: 'setUser: %O', cognitoUser });

    if (!cognitoUser) {
      return dispatch({ type: 'SET_USER', user: null });
    }

    const groups = cognitoUser.getSignInUserSession().getAccessToken().payload['cognito:groups'] || [];
    const user: User = {
      mercuryContactId: null,
      salesforceAccountId: null,
      id: cognitoUser.getUsername(),
      username: cognitoUser.getUsername(),
      email: cognitoUser.attributes.email,
      mobile: cognitoUser.attributes.phone_number,
      groups,
    };
    dispatch({ type: 'SET_USER', user });
    identify(user.id, {
      email: user.email,
      email_verified: true,
      first_name: cognitoUser.attributes.given_name,
      phone_number: cognitoUser.attributes.phone_number,
    });
    track({ event: 'auth_user_loggedin', userId: user.id });
    setLoggingUser(user);
  };
  const signUp = async (
    email: string,
    password: string,
    name: string,
    number: string,
    stringifiedCalculatorValues: string,
    utm_campaign: string,
    utm_source: string
  ) => {
    dispatch({ type: 'SIGNUP' });

    try {
      const result: ISignUpResult = await Auth.signUp({
        username: email,
        password,
        attributes: {
          phone_number: number,
          given_name: name,
          'custom:privacy_version': NEXT_PUBLIC_PRIVACY_VERSION,
          'custom:calculator_values': stringifiedCalculatorValues,
          'custom:privacy_accepted_at': dayjs().tz('Australia/Sydney').format('YYYY-MM-DDTHH:mm:ssZ'),
          'custom:campaign_code': utm_campaign,
          'custom:source': utm_source,
        },
      });
      logger.debug({ msg: 'Auth.signUp.then %O', result });
      dispatch({ type: 'SIGNUP_SUCCESS', result });
      // ATTN: On sign-up success, `getUsername` _is the email_. Then becomes `id` once verified...
      identify(result.userSub, {
        email: result.user.getUsername(),
        email_verified: false,
        created_at: Date.now(),
      });
      track({ event: 'auth_user_created', userId: result.userSub });
    } catch (error) {
      logger.debug({ msg: 'Auth.signUp.catch %O', error });
      dispatch({ type: 'SIGNUP_FAILURE', error });
      throw error;
    }
  };
  const confirmSignUp = (id: string, code: string) => {
    dispatch({ type: 'CONFIRM_SIGN_UP' });
    Auth.confirmSignUp(id, code)
      .then((result) => {
        logger.debug({ msg: 'CONFIRM_SIGN_UP_SUCCESS %O', result });
        dispatch({ type: 'CONFIRM_SIGN_UP_SUCCESS', result });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'CONFIRM_SIGN_UP_FAILURE', error });
      });
  };
  const signOut = (redirectUrl?: string, fromEverywhere = false) => {
    // Setting global (from everywhere) to true revokes every JWT/Refresh token
    // belonging to the current user, even on other devices.
    Auth.signOut({ global: fromEverywhere })
      .then(() => {
        dispatch({ type: 'SIGNOUT' });
        clearLoggingUser();
      })
      .catch((error) => {
        logger.debug({ msg: 'Auth.signOut.catch %O', error });
        dispatch({ type: 'SIGNOUT_FAILURE', error });

        // Remove localStorage items that start with Cognito
        Object.entries(localStorage)
          .filter(([key]) => key.toUpperCase().startsWith('COGNITO'))
          .forEach(([key]) => localStorage.removeItem(key));
      })
      .finally(() => {
        router.push(redirectUrl ? redirectUrl : homePages.PUBLIC_PAGE);
      });
  };
  const logIn = (email: string, password: string) => {
    dispatch({ type: 'LOGIN' });
    Auth.signIn({ username: email, password })
      .then((result) => {
        logger.debug({ msg: 'LOGIN_SUCCESS %O', result });
        dispatch({ type: 'LOGIN_SUCCESS', result });
      })
      .catch((error) => {
        dispatch({ type: 'LOGIN_FAILURE', error });
      });
  };
  const resendSignUpEmail = async (username: string) => {
    dispatch({ type: 'RESEND_SIGNUP' });

    try {
      const result = await Auth.resendSignUp(username);
      dispatch({ type: 'RESEND_SIGNUP_SUCCESS', result });
    } catch (error) {
      dispatch({ type: 'RESEND_SIGNUP_FAILURE', error });
    }
  };
  const resetPasswordRequest = (username: string) => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST' });

    Auth.forgotPassword(username)
      .then((result) => {
        dispatch({ type: 'RESET_PASSWORD_REQUEST_SUCCESS', result });
      })
      .catch((error) => {
        dispatch({ type: 'RESET_PASSWORD_REQUEST_FAILURE', error });
      });
  };

  const resetPassword = async (code: string, username: string, password: string) => {
    dispatch({ type: 'RESET_PASSWORD' });

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'RESET_PASSWORD_FAILURE', error });
      throw error;
    }
  };

  const resetSignUp = () => dispatch({ type: 'RESET_SIGNUP' });
  const redirectToSignUp: MouseEventHandler<Element> = (event) => {
    event?.preventDefault();

    resetSignUp();
    if (!state.user) {
      router.push(pages.SIGN_UP_PAGE);
    }
  };

  const resetAuthActionState = () => dispatch({ type: 'RESET_AUTH_ACTION_STATE' });

  useEffect(() => {
    // TODO: deep links
    const setUserFromCurrentSession = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        logger.debug({ msg: 'setUserFromCurrentSession user', cognitoUser });
        setUser(cognitoUser);
      } catch {
        setUser(null);
      }
    };

    const listener = (data: HubCapsule) => {
      logger.debug({ msg: 'Hub.listener(auth)', data });
      setUserFromCurrentSession();
    };

    Hub.listen('auth', listener);

    setUserFromCurrentSession();

    return () => Hub.remove('auth', listener);
  }, []);

  const value = useMemo<CognitoAuthContextValue>(
    () => ({
      ...state,
      signUp,
      signOut,
      resendSignUpEmail,
      resetSignUp,
      redirectToSignUp,
      logIn,
      resetPasswordRequest,
      resetPassword,
      confirmSignUp,
      resetAuthActionState,
    }),
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <CognitoAuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
  const context = useContext(CognitoAuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used with AuthProvider');
  }
  return context;
};

export const AuthContext: FC = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};
