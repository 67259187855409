import getConfig, { getAppUrl } from 'config';
import pino, { DestinationStream, Logger } from 'pino';
import datadog from 'pino-datadog';
import { redactAxiosError } from 'utils/redactor/redact-axios-error';
import { NextApiRequest } from 'next';
import browserLogger, { levelToStatus } from './browser';
import { toStackTraceString, computeStackTrace } from '@datadog/browser-core';

const { DATADOG_API_KEY, NEXT_PUBLIC_LOG_LEVEL, NEXT_PUBLIC_ENVIRONMENT } = getConfig();

const isBrowser = typeof window !== 'undefined';

let stream: pino.DestinationStream = process.stdout;
if (!isBrowser && ['test', 'production'].includes(NEXT_PUBLIC_ENVIRONMENT)) {
  stream = datadog.createWriteStreamSync({
    service: new URL(getAppUrl()).host,
    apiKey: DATADOG_API_KEY,
    ddsource: 'web-api',
    ddtags: `env:${NEXT_PUBLIC_ENVIRONMENT}`,
  }) as DestinationStream;
}

const options: pino.LoggerOptions = {
  level: NEXT_PUBLIC_LOG_LEVEL,
  browser: {
    asObject: true,
    write: {
      info: ({ msg, level, ...rest }: Record<string, any>) => {
        browserLogger.log(msg || '', rest, levelToStatus(level));
      },
      error: ({ msg, level, ...rest }: Record<string, any>) => {
        const args = { ...rest };
        if (rest.error) {
          args.error = {
            origin: 'logger',
            stack: toStackTraceString(computeStackTrace(rest.error)),
          };
        }
        browserLogger.log(msg || '', args, levelToStatus(level));
      },
      warn: ({ msg, level, ...rest }: Record<string, any>) => {
        browserLogger.log(msg || '', rest, levelToStatus(level));
      },
      debug: ({ msg, level, ...rest }: Record<string, any>) => {
        browserLogger.log(msg || '', rest, levelToStatus(level));
      },
    },
  },
};

const logger = pino(options, stream);

export function logError(
  error: string | Error,
  tags?: Record<string, string>,
  extra?: Record<string, string>,
  requestContext?: NextApiRequest
) {
  const exception = typeof error === 'string' ? new Error(error) : redactAxiosError(error);
  extra = extra ?? {};
  if (requestContext) {
    extra.RequestUrl = requestContext.url;
    extra.RequestMethod = requestContext.method;
  }

  if (error instanceof Error && error.name == 'AggregateError') {
    for (const e of new Array((error as AggregateError).errors)) {
      (requestContext?.log || logger).error({ error: e });
    }
  } else {
    (requestContext?.log || logger).error({ error: exception }, exception.message, tags, extra);
  }
}

export default logger as Logger;
