import { TitanConfig, TitanPublicConfig, TitanSecrets } from './types';

export type { TitanConfig, TitanPublicConfig, TitanSecrets } from './types';
export { TitanApiClient, CacheKeys } from './types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { config, secrets, publicConfig } = require(`./${process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'}`);

export const getPublicConfig = () => publicConfig;

export const getSecrets = (): TitanSecrets => secrets;

export const getConfig = (): TitanPublicConfig & TitanConfig & TitanSecrets =>
  ({
    ...publicConfig,
    ...config,
    ...secrets,
  } as TitanPublicConfig & TitanConfig & TitanSecrets);

export default getConfig;

export function getAppUrl() {
  if (process.env.NEXT_PUBLIC_APP_URL) {
    return process.env.NEXT_PUBLIC_APP_URL;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  return 'https://localhost:3000';
}
