import Head from 'next/head';
import { SeoMetaTagType } from 'react-datocms';

type Props = {
  tags: SeoMetaTagType[];
  excludeHead?: boolean;
};

export function MetaData(props: Props) {
  const tags = props.tags?.map((x) => {
    if (x.tag === 'title') {
      return <title key="document-title">{x.content}</title>;
    }

    if (x.tag === 'meta' && x.attributes) {
      if (x.attributes.property) {
        return <meta key={x.attributes.property} property={x.attributes.property} content={x.attributes.content} />;
      }

      if (x.attributes.name) {
        return <meta key={x.attributes.name} name={x.attributes.name} content={x.attributes.content} />;
      }
    }

    return null;
  });

  if (props.excludeHead) {
    return <>{tags}</>;
  }

  return <Head>{tags}</Head>;
}
