import { TitanConfig, TitanPublicConfig, TitanSecrets } from './types';

/**
 * This is used by vercel preview environments, some items may be required to come from vercel env
 */

export const config: TitanConfig = {
  AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION,
  AWS_DYNAMODB_USERDATA_TABLE: 'titan-iris-UserData',
  AWS_DYNAMODB_UNIQUEDATA_TABLE: 'titan-iris-UniqueUserData',
  AWS_DYNAMODB_VERIFYEMAIL_TABLE: 'titan-iris-VerifyUserEmail',
  AWS_DYNAMODB_VIDEOSESSION_TABLE: 'titan-iris-VideoSession',
  AWS_DYNAMODB_SIGNUPINVITES_TABLE: 'titan-iris-SignupInvitations',
  AWS_CANVAS_UPLOADS_BUCKET: 'true-dev-canvas-uploads',
  PARTNER_PORTAL_URL: 'https://titan-au.force.com/registration/s/',
  CUSTOMER_SITE_ID: 'a15cf7d277843875d0f0',
  CUSTOMER_TRACKING_API_KEY: '5a9f2439f62edaa611b1',
  CUSTOMER_API_KEY: '0b0ce239feec451c9380ef8ac285d43f',
  CUSTOMER_FROM_EMAIL: 'support@titansave.us',
  CUSTOMER_TRANSACTIONAL_MESSAGE_ID: '5',
  CUSTOMER_VERIFY_EMAIL_TRANSACTIONAL_MESSAGE_ID: '8',
  CUSTOMER_INVITE_PTCS_MESSAGE_ID: '10',
  CUSTOMER_INVITE_G_MESSAGE_ID: '11',
  CUSTOMER_APPOINTMENT_CONFIRMATION_MESSAGE_ID: '12',
  CUSTOMER_EMAIL_CHANGE_NOTIFICATION_TRANSACTIONAL_MESSAGE_ID: '13',
  CUSTOMER_PASSWORD_CHANGE_NOTIFICATION_TRANSACTIONAL_MESSAGE_ID: '14',
  CUSTOMER_APPOINTMENT_CONFIRMATION_EMAIL_TEXT_MESSAGE_ID: '16',
  MERCURY_API_BASE_URL: 'https://apis.connective.com.au/mercury/v1',
  MERCURY_NEXUS_API_BASE_URL: 'https://apis.connective.com.au/maf/v1',
  MERCURY_5_FILEUTILS_BASE_URL: 'https://m5api.connective.com.au/api/fileUtils/upload',
  MERCURY_5_COMPANY: '2966c8c8-661c-48e7-b848-de4c0d192ef4',
  MERCURY_5_PARTNER_ID: '2966c8c8-661c-48e7-b848-de4c0d192ef4',
  MERCURY_SITEAPI_BASE_URL: 'https://m5.connective.com.au/api',
  MERCURY_SITEAPI_USERNAME: 'CA78969',
  MS_TEAMS_BROKER_WEBHOOK_URL:
    'https://titanplatform.webhook.office.com/webhookb2/a896127b-cf37-4dba-b74b-c64be6941fd4@8efc0f36-35b0-4dd0-b145-3accfc5e0c8a/IncomingWebhook/b89438256b5040dfa5137c173d955183/753f239c-cffd-4096-897c-5c31448ffc80',
  SALESFORCE_LOGIN_URL: 'https://test.salesforce.com',
  SALESFORCE_USERNAME: 'engineering+20211122@titan.inc',
  SALESFORCE_BASE_URL: 'https://titan-au--titansb.my.salesforce.com',
  TWILIO_ACCOUNT_SID: 'AC081893accd8efa625df33c0c67ac1df2',
  TWILIO_ACCOUNT_API_KEY: 'SKf965ae50f304a4b7646b0bec0b37f3e1',
  TWILIO_VERIFY_SERVICE_SID: 'VAc5335f4b99473e4b54cefe8997be1d43',
  TWILIO_MESSAGE_SERVICE_SID: 'MGa07ba42786807d4743bddde2601ec471',
  TWILIO_COMPOSE_VIDEO: 'false',
  MS_GRAPH_API_CLIENT_ID: 'c9f24ae3-54d6-46bd-8261-f095c3b3e0d3',
  MS_GRAPH_API_TENNANT_ID: '8efc0f36-35b0-4dd0-b145-3accfc5e0c8a',
  QUICKLI_BASE_URL: 'https://app.quickli.com.au',
  QUICKLI_API_URL: 'https://app.quickli.com.au/api',
  QUICKLI_TEAM_ID: '618339bb4398cc00093d2cc0',
  QUICKLI_SIGN_IN_EMAIL: 'brendan@truesavings.com',
  QUICKLI_TOKEN_RETRIEVAL_EMAIL: 'thomas.anderson@truesavings.com',
  CUSTOMER_BETA_API_BASE_URL: 'https://beta-api.customer.io/v1/api',
};

export const secrets: TitanSecrets = {
  AWS_ACCESS_KEY_ID_APP: process.env.AWS_ACCESS_KEY_ID_APP,
  AWS_SECRET_ACCESS_KEY_APP: process.env.AWS_SECRET_ACCESS_KEY_APP,
  MERCURY_API_KEY: 'QJevu2r8GP1EKb1jAqskF4eXe1GnyQAM3m35fQaS',
  MERCURY_API_TOKEN: 'a9f00d88-9e5c-4617-98c2-fe5e61220800',
  MERCURY_SITEAPI_PASSWORD: 'Connective1234!',
  MS_GRAPH_API_CLIENT_SECRET: 'bcn7Q~NWhtSHI43Zw_remPQhH4siucDEqyc4t',
  SALESFORCE_PASSWORD: 'yka7MDQ-dvu3vtc.ubf',
  SALESFORCE_SECRET_TOKEN: '8Q7bD6u3QRCSoxHlHPMlpamA',
  TITAN_API_KEY: 'some-unique-guid',
  TITAN_CRYPTO_KEY: 'some-unique-crypto-key',
  TITAN_SALESFORCE_API_KEY: 'this-is-for-salesforce-to-use',
  TWILIO_ACCOUNT_API_SECRET: 'EIIDeOaDzujwZ1XoEnfDCE9UYlpkibyt',
  TWILIO_ACCOUNT_AUTH_TOKEN: '42b871cf2819eb77a1869d2145d84684',
  VIDEO_SESSION_TOKEN_SECRET: 'biglongsecretstring',
  DATABASE_URL:
    'postgresql://true_dev_admin:F0aZYFKPhRAb8BFfz9Sb@pg-truesavings-dev-1.ctufqqfohwxz.ap-southeast-2.rds.amazonaws.com:5432/postgres?schema=public',
  DATADOG_API_KEY: 'b43dde8321d1166834989ae9ab9fc975',
  QUICKLI_API_KEY: process.env.QUICKLI_API_KEY,
};

export const publicConfig: TitanPublicConfig = {
  NEXT_PUBLIC_AWS_REGION: 'ap-southeast-2',
  NEXT_PUBLIC_AWS_COGNITO_DOMAIN: 'titan-iris',
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID: 'ap-southeast-2_QItErFaQE',
  NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID: '7iv38bit9193tei0ajkr010gnq',
  NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY: 'yf2bkiUUn1IErGaWZNovf9utNRtMtZQX',
  NEXT_PUBLIC_SUPPORT_EMAIL: 'support@titansave.us',
  NEXT_PUBLIC_PRIVACY_VERSION: '2021-04-08',
  NEXT_PUBLIC_ENVIRONMENT: 'test',
  NEXT_PUBLIC_CALCULATOR_MIN_SAVING: 50,
  NEXT_PUBLIC_DATOCMS_API_TOKEN: process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN as string,
  NEXT_PUBLIC_DATOCMS_API_URL: 'https://graphql.datocms.com',
  NEXT_PUBLIC_TWILIO_LOG_ENABLED: 'true',
  NEXT_PUBLIC_TWILIO_LOG_LEVEL: 'error',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: 'GTM-MRBJ77V',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT: 'env-14',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH: 'QklQ8fejeY8l5kTANWYvow',
  NEXT_PUBLIC_GOOGLE_OPTIMIZE_TIMEOUT: 500,
  NEXT_PUBLIC_ON_DEMAND_POP_UP_EXPERIMENT_ID: '3WGByl0vQ-K_aK_n0RrxNQ',
  NEXT_PUBLIC_RESULTS_EXPERIMENT_ID: 'cgta5mUUS_KEHBRC9dn4og',
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
  NEXT_PUBLIC_LOG_LEVEL: 'info',
  NEXT_PUBLIC_LOGGING_DISABLED: false,
};
