import uuid from 'uuid/v4';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { getAppUrl, getPublicConfig } from 'config';

const {
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_LOG_LEVEL,
  NEXT_PUBLIC_LOGGING_DISABLED,
  NEXT_PUBLIC_ENVIRONMENT,
} = getPublicConfig();

datadogLogs.init({
  clientToken: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  forwardErrorsToLogs: false,
  sampleRate: 100,
  env: NEXT_PUBLIC_ENVIRONMENT,
  service: new URL(getAppUrl()).hostname,
});

const logger = datadogLogs.createLogger('app', {
  level: NEXT_PUBLIC_LOG_LEVEL as StatusType,
  handler: NEXT_PUBLIC_LOGGING_DISABLED ? 'console' : ['console', 'http'],
});

export function setUser(user: User) {
  if (user) {
    datadogLogs.addLoggerGlobalContext('user.id', user.id);
    datadogLogs.addLoggerGlobalContext('user.email', user.email);
  }
}

export function setCanvasContext(sessionId: string, participantType: string) {
  datadogLogs.addLoggerGlobalContext('canvas.sessionId', sessionId);
  datadogLogs.addLoggerGlobalContext('canvas.participantType', participantType);
}

export function clearUser() {
  datadogLogs.removeLoggerGlobalContext('user.id');
  datadogLogs.removeLoggerGlobalContext('user.email');
}

export const generateTracingId = () => {
  const traceId = uuid();
  datadogLogs.addLoggerGlobalContext('trace_id', traceId);
  return traceId;
};

export function levelToStatus(level): StatusType {
  if (level === 10 || level === 20) {
    return 'debug';
  }
  if (level === 40) {
    return 'warn';
  }
  if (level === 50) {
    return 'error';
  }
  return 'info';
}

export default logger;
