export const images = {
  FILE: '/images/home/file.svg',
  BANK: '/images/home/bank.svg',
  HEADPHONES: '/images/home/headphones.svg',
  CLOCK: '/images/home/clock.svg',
  CLOCK_DARK: '/images/home/clock-dark.svg',
  CHEVRONS_RIGHT_BLACK: '/images/home/chevrons-right-black.svg',
  LINK: '/images/home/link.svg',
  PAPERCLIP: '/images/home/paperclip.svg',
  REFRESH_CONNECTIONS: '/images/home/refresh-connections.svg',
  EXPERT: '/images/home/expert.svg',
  LOGO_WHITE: '/images/logo-white.svg',
  LOGO_WHITE_YELLOW_DOT: '/images/logo-white-yellow-dot.svg',
  LOGO: '/images/logo.svg',
  HAMBURGER: '/images/hamburger.svg',
  HAMBURGER_WHITE: '/images/hamburger-white.svg',
  CLOSE_WHITE: '/images/close-white.svg',
  PHONE_WHITE: '/images/phone-white.svg',
  PHONE_BLUE: '/images/phone-blue.svg',
  PHONE_YELLOW: '/images/phone-yellow.svg',
  EMAIL_YELLOW: '/images/email-yellow.svg',
  CHEVRON_RIGHT_WHITE: '/images/chevron-right-white.svg',
  CALENDAR_YELLOW: '/images/calendar-yellow.svg',
  CANVAS_YELLOW: '/images/canvas-yellow.svg',
  INSTAGRAM: '/images/instagram.svg',
  INSTAGRAM_WHITE: '/images/instagram-white.svg',
  FACEBOOK: '/images/facebook.svg',
  FACEBOOK_WHITE: '/images/facebook-white.svg',
  LINKEDIN: '/images/linkedin.svg',
  LINKEDIN_WHITE: '/images/linkedin-white.svg',
  LINKEDIN_BLACK: '/images/linkedin-black.svg',
  EMAIL: '/images/public/email.svg',
  EMAIL_WHITE: '/images/public/email-white.svg',
  CHECK: '/images/home/check.svg',
};

export const pages = {
  PUBLIC_PAGE: '/',
  HOME_PAGE: '/home',
};

export const apis = {
  HEALTHZ: '/api/healthz',
};
