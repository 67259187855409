import React from 'react';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';

export const ROBOTO_FONT_FAMILY = 'Roboto, Arial, Helvetica, Sans-Serif';
export const MONTBLANC_FONT_FAMILY = 'MontBlanc, "Arial Black", Helvetica, Sans-Serif';

export const Colors = {
  Vegemite: '#000',
  Pavlova: '#FFF',
  SpongeCake: '#F5E7DC',
  Cockatoo: '#F75757',
  Kangaroo: '#EAC189',
  Kangaroo2: '#D1A07C',
  Blue: '#0055DD',
  Blue2: '#3670F6',
  GoldRush: '#FFE700',
  GumTree: '#07CB9C',
  Dark1: '#1B1B1B',
  Dark2: '#404040',
  Dark3: '#696969',
  Dark4: '#999999',
  Dark5: '#B0B0B0',
  Dark6: '#D0D0D0',
  Dark7: '#E3E3E3',
  Dark8: '#EEEEEE',
  Dark9: '#F7F7F7',
  Background: '#F7F7F7',
  DisabledBreadcrumbs: '#D7D6D9',
};

interface IThemeProps {
  children: unknown;
}

export function Theme(props: IThemeProps) {
  const options: ThemeOptions = {
    spacing: 8,
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 640,
        lg: 1024,
        xl: 1440,
      },
    },
    typography: {
      h1: {
        fontFamily: MONTBLANC_FONT_FAMILY,
        letterSpacing: '-0.03em',
        fontSize: '2.125rem',
        color: Colors.Vegemite,
      },
      h2: {
        fontFamily: MONTBLANC_FONT_FAMILY,
        letterSpacing: '-0.02em',
        fontSize: '1.75rem',
        color: Colors.Vegemite,
      },
      h3: {
        fontFamily: ROBOTO_FONT_FAMILY,
        fontWeight: 'bold',
        fontSize: '1.75rem',
        color: Colors.Vegemite,
      },
      button: {
        fontFamily: MONTBLANC_FONT_FAMILY,
        letterSpacing: '-0.02em',
      },
    },
    props: {
      MuiFormControl: { margin: 'normal' },
      MuiButton: { disableElevation: true, variant: 'contained', color: 'primary' },
      MuiLink: { underline: 'always' },
      MuiTextField: { variant: 'outlined', margin: 'normal' },
      MuiCard: { elevation: 0 },
      MuiCheckbox: {
        color: 'primary',
      },
    },
    palette: {
      primary: {
        contrastText: Colors.Pavlova,
        main: Colors.Vegemite,
      },
      secondary: {
        contrastText: Colors.Vegemite,
        main: Colors.Pavlova,
      },
      background: {
        default: Colors.Background,
      },
      text: {
        primary: Colors.Vegemite,
      },
      error: {
        main: Colors.Cockatoo,
      },
      Dark1: { main: Colors.Dark1, contrastText: Colors.Pavlova },
      Dark2: { main: Colors.Dark2 },
      Dark3: { main: Colors.Dark3 },
      Dark4: { main: Colors.Dark4 },
      Dark5: { main: Colors.Dark5 },
      Dark6: { main: Colors.Dark6, contrastText: Colors.Dark1 },
      Dark7: { main: Colors.Dark7 },
      Dark8: { main: Colors.Dark8 },
      Dark9: { main: Colors.Dark9 },
      Vegemite: { main: Colors.Vegemite },
      Pavlova: { main: Colors.Pavlova },
      SpongeCake: { main: Colors.SpongeCake },
      Cockatoo: { main: Colors.Cockatoo },
      Blue: { main: Colors.Blue, contrastText: Colors.Pavlova },
      Blue2: { main: Colors.Blue2, contrastText: Colors.Pavlova },
      GoldRush: { main: Colors.GoldRush },
      GumTree: { main: Colors.GumTree },
      Kangaroo: { main: Colors.Kangaroo },
      Kangaroo2: { main: Colors.Kangaroo2 },
      Background: { main: Colors.Background },
      DisabledBreadcrumbs: { main: Colors.DisabledBreadcrumbs },
      naturalLanguagePlaceholder: Colors.Dark6,
      canvasBroker: Colors.Blue,
      canvasCustomer: Colors.GumTree,
    },
    overrides: {
      // margin & padding zeroed to avoid scrolling bug caused by negative margins
      MuiInputLabel: {
        asterisk: { color: Colors.Cockatoo },
        root: {
          '&.Mui-required': { paddingLeft: '0.8em' },
          '&.Mui-required > .MuiInputLabel-asterisk': { left: '0', position: 'absolute' },
        },
      },
      MuiGrid: {
        root: {
          '&.MuiGrid-root.MuiGrid-spacing-xs-1.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-2.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-3.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-4.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-5.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-6.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-7.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-8.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-9.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-10.MuiGrid-container': {
            marginTop: '0',
            marginBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-1.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-2.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-3.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-4.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-5.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-6.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-7.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-8.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-9.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '&.MuiGrid-root.MuiGrid-spacing-xs-10.MuiGrid-container>.MuiGrid-root.MuiGrid-item': {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
      },
      MuiContainer: {
        root: {
          '@media (min-width:1024px)': {
            paddingLeft: '20px!important',
            paddingRight: '20px!important',
          },
          '@media (max-width:639px)': {
            paddingLeft: '16px!important',
            paddingRight: '16px!important',
          },
          paddingLeft: '24px!important',
          paddingRight: '24px!important',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: '1.0625rem',
          padding: '12px 24px;',
          borderRadius: '0',
          maxHeight: '48px',
          transition: 'color .01s',
        },
        sizeSmall: { fontSize: '0.9375rem', padding: '8px 22px;' },
        sizeLarge: { fontSize: '1.1875rem', padding: '20px 30px;' },
        outlined: { padding: null },
        outlinedSizeSmall: { fontSize: '0.9375rem', padding: '7px 22px;' },
        outlinedSizeLarge: { fontSize: '1.1875rem', padding: '19px 30px;' },
        containedSizeSmall: { fontSize: '0.9375rem', padding: '8px 22px;' },
        containedSizeLarge: { fontSize: '1.1875rem', padding: '20px 30px;' },
      },
      MuiCard: { root: { border: 'transparent 0.1px solid', padding: '32px' } },
      MuiDialogActions: { root: { justifyContent: 'center' } },
      MuiDialog: { paper: { borderRadius: '20px' } },
      MuiOutlinedInput: { root: { borderRadius: '0' }, input: { padding: '16.5px 14px;' } },
      MuiLink: { root: { fontFamily: ROBOTO_FONT_FAMILY, fontWeight: 500, cursor: 'pointer' } },
      MuiFormControl: { root: { display: 'block' } },
      MuiRadio: {
        colorSecondary: {
          color: common.black,
          '&$checked': {
            color: common.black,
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `solid 2px ${Colors.Blue} !important`,
            transition: 'none',
          },
          '&:after': {
            borderBottom: `solid 2px ${Colors.Blue} !important`,
            transition: 'none',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `solid 2px ${Colors.Blue} !important`,
            transition: 'none',
          },
        },
      },
    },
  };

  const labOverrides = {
    props: {
      MuiSkeleton: { variant: 'rect' },
    },
    overrides: {
      MuiToggleButton: {
        root: {
          textTransform: 'none',
          backgroundColor: Colors.Pavlova,
          '&$selected': {
            backgroundColor: Colors.Vegemite,
            color: Colors.Pavlova,
          },
        },
      },
    },
  };

  options.props = { ...options.props, ...labOverrides.props };
  options.overrides = { ...options.overrides, ...labOverrides.overrides };
  const muiTheme = createTheme(options);

  return <MuiThemeProvider theme={muiTheme}>{props.children}</MuiThemeProvider>;
}
