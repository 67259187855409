// @ts-strict

import { PreSignUpData } from 'models/pre-sign-up-data';
import { useMemo } from 'react';
import createPersistedState from 'use-persisted-state';

export const PRE_SIGN_UP_DATA_KEY = 'titan:pre-sign-up-data';
const usePersistedRefinanceCalculatorState = createPersistedState(`${PRE_SIGN_UP_DATA_KEY}:refinance-savings`);
const usePersistedAttributionState = createPersistedState(`${PRE_SIGN_UP_DATA_KEY}:attribution`);
const usePersistedBorrowingPowerCalculatorState = createPersistedState(`${PRE_SIGN_UP_DATA_KEY}:borrowing-power`);

export function usePreSignUpData() {
  const [refinanceSavings, setRefinanceData] =
    usePersistedRefinanceCalculatorState<PreSignUpData['refinanceSavings']>();
  const [attribution, setAttributionData] = usePersistedAttributionState<PreSignUpData['attribution']>();
  const [borrowingPower, setBorrowingPowerData] =
    usePersistedBorrowingPowerCalculatorState<PreSignUpData['borrowingPower']>();

  return useMemo(
    () => ({
      data: {
        refinanceSavings,
        borrowingPower,
        attribution,
      },
      setRefinanceData,
      setBorrowingPowerData,
      setAttributionData,
    }),
    [refinanceSavings, borrowingPower, attribution, setRefinanceData, setBorrowingPowerData, setAttributionData]
  );
}
