import { useEffect } from 'react';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import googleTagManager from '@analytics/google-tag-manager';
import { useRouter } from 'next/router';
import getConfig from 'config';
import { CalculatorInputs, SelectedLender } from 'models/calculators';

// event name format feature_noun_verb (past tense verb)
// events should be recorded in confluence https://titanplatform.atlassian.net/l/c/QsdXn3eb
export type auth_user_enriched = { event: 'auth_user_enriched'; userId: string };
export type auth_user_created = { event: 'auth_user_created'; userId: string };
export type auth_user_loggedin = { event: 'auth_user_loggedin'; userId: string };
export type appointment_scheduledcall_created = {
  event: 'appointment_scheduledcall_created';
  type?: string;
  scheduled_for?: string;
  authenticated?: string;
};
export type documents_upload_files_removed = { event: 'documents_upload_files_removed' };
export type documents_upload_files_added = { event: 'documents_upload_files_added' };
export type documents_upload_completed = { event: 'documents_upload_completed' };
export type documents_upload_cancelled = { event: 'documents_upload_cancelled' };
export type referral_peercode_shared = { event: 'referral_peercode_shared'; type: 'shared' | 'clipboard' };
export type canvas_session_created = {
  event: 'canvas_session_created';
  sessionId: string;
  isUserAuthenticated: boolean;
};
export type canvas_lobby_entered = { event: 'canvas_lobby_entered' };
export type canvas_lobby_exited = {
  event: 'canvas_lobby_exited';
  waitSeconds: number;
  outcome: 'connected' | 'rescheduled' | 'aborted';
};
export type canvas_whiteboard_entered = { event: 'canvas_whiteboard_entered' };
export type canvas_whiteboard_exited = { event: 'canvas_whiteboard_exited'; durationSeconds: number };
export type canvas_rating_created = { event: 'canvas_rating_created'; rating: string };
export type home_canvas_download = { event: 'home_canvas_download' };

export type appointments_new_data_captured = { event: 'appointments_new_data_captured' };
export type appointments_new_otp_confirmed = { event: 'appointments_new_otp_confirmed' };

export type mortgage_calculator_refinance_productselected = {
  event: 'mortgage_calculator_refinance_productselected';
  product: SelectedLender;
  savings: unknown;
  loan_type: string;
  selected_variant: string;
  name: string;
  category: string;
  label: string;
  value: unknown;
  experiment_name: string;
  variant_name: string;
};
export type mortgage_calculator_borrowingpower_calculated = {
  event: 'mortgage_calculator_borrowingpower_calculated';
  borrowingPowerLower: string;
  borrowingPowerUpper: string;
};
export type mortgage_calculator_borrowingpower_userinterested = {
  event: 'mortgage_calculator_borrowingpower_userinterested';
};

export type mortgage_calculator_refinance_calculated = {
  event: 'mortgage_calculator_refinance_calculated';
  maxSaving: number;
} & CalculatorInputs;

export type public_article_share = {
  event: 'public_article_share';
  method: 'facebook' | 'linkedIn' | 'email' | 'instagram' | 'device' | 'clipboard' | 'twitter';
  slug: string;
};

type analyticsEvent =
  | auth_user_enriched
  | auth_user_created
  | auth_user_loggedin
  | appointment_scheduledcall_created
  | documents_upload_files_removed
  | documents_upload_cancelled
  | documents_upload_files_added
  | documents_upload_completed
  | referral_peercode_shared
  | mortgage_calculator_refinance_productselected
  | mortgage_calculator_refinance_calculated
  | mortgage_calculator_borrowingpower_calculated
  | mortgage_calculator_borrowingpower_userinterested
  | canvas_whiteboard_entered
  | canvas_whiteboard_exited
  | canvas_session_created
  | canvas_lobby_entered
  | canvas_lobby_exited
  | canvas_rating_created
  | home_canvas_download
  | appointments_new_data_captured
  | appointments_new_otp_confirmed
  | public_article_share;

// FIXME: next build fails on this without default value
const {
  NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY = '000',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID = 'GTM-MGXBMFZ',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT = '',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH = '',
} = getConfig();

let gtmConfig: googleTagManager[0] = {
  containerId: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
};

if (NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH && NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT) {
  gtmConfig = {
    ...gtmConfig,
    preview: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT,
    auth: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  };
}

const plugins = [
  segmentPlugin({
    writeKey: NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
    disableAnonymousTraffic: false,
  }),
  googleTagManager(gtmConfig),
];

const analytics = Analytics({
  plugins,
  debug: process.env.NODE_ENV === 'development',
});

export const identify = analytics.identify;

/**
 * Track generic event like those defined inside CMS or dynamic content. Otherwise use
 * track function instead.
 *
 * @param eventName
 * @returns
 */
export const trackGenericEvent: (eventName: string) => Promise<void> = (eventName) => {
  return analytics.track(eventName);
};

export const track: (event: analyticsEvent) => Promise<void> = (event: analyticsEvent) => {
  const { event: eventName, ...payload } = event;
  return analytics.track(eventName, payload);
};

export const useAnalytics = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    // Fire page views on routing
    // We need to wrap it in a rAF to ensure the correct data is sent to Segment
    // https://github.com/zeit/next.js/issues/6025
    requestAnimationFrame(() => {
      analytics.page();
    });
  }, [asPath]);

  return analytics;
};
