import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { AttributionModel } from 'models/attribution-model';
import { equals } from 'ramda';
import { usePreSignUpData } from 'hooks/use-pre-sign-up-data';

export const useAttribution = (): [AttributionModel, (values: AttributionModel) => void] => {
  const { data, setAttributionData } = usePreSignUpData();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const {
      utm_source = null,
      utm_medium = null,
      utm_campaign = null,
      utm_term = null,
      utm_content = null,
    } = router.query || {};

    if (utm_source || utm_medium || utm_campaign) {
      const nv: AttributionModel = {
        utm_source: utm_source as string,
        utm_medium: utm_medium as string,
        utm_campaign: utm_campaign as string,
        utm_term: utm_term as string,
        utm_content: utm_content as string,
      };

      // if it hasnt materially changed dont set
      if (!equals(data?.attribution, nv)) {
        setAttributionData(nv);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return [data?.attribution, setAttributionData];
};
