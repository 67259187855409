import type { APIParameters } from './types';
import axios from 'axios';
import getConfig from 'config';
import logger from 'logging';

const {
  NEXT_PUBLIC_DATOCMS_API_URL = 'https://graphql.datocms.com',
  NEXT_PUBLIC_DATOCMS_API_TOKEN,
  NEXT_PUBLIC_ENVIRONMENT = 'production',
} = getConfig();

type CmsError = {
  message: string;
  locations: { line: number; column: number }[];
  path: string[];
  extensions: {
    code: string;
    fragmentName: string;
  };
};

type CmsResponse<T> = {
  data: T;
  errors: CmsError[];
};

export async function graphAPI<T>(
  query: string,
  { variables, preview = NEXT_PUBLIC_ENVIRONMENT !== 'production' }: APIParameters = {}
) {
  logger.debug({ preview, variables }, query);

  const res = await axios.post<CmsResponse<T>>(
    NEXT_PUBLIC_DATOCMS_API_URL + (preview ? '/preview' : ''),
    {
      query,
      variables,
    },
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${NEXT_PUBLIC_DATOCMS_API_TOKEN}`,
      },
    }
  );

  const json = await res?.data;

  if (json.errors) {
    console.error('Error from cms', json.errors);
    throw new Error('Failed to fetch API');
  }

  return json.data as T;
}
