export const pages = {
  LOG_IN_PAGE: '/log-in',
  LOG_OUT_PAGE: '/log-out',
  SIGN_UP_PAGE: '/sign-up',
  VERIFY_EMAIL_PAGE: '/sign-up/verify-email',
  RESEND_VERIFY_EMAIL: '/sign-up/resend-verify-email',
  RESET_PASSWORD_PAGE: '/reset-password',
  RESET_PASSWORD_EMAIL_SENT_PAGE: '/reset-password/email-sent',
  RESET_PASSWORD_SET_PASSWORD_PAGE: '/reset-password/set-password',
  NOT_FOUND: '/404',
};

export const apis = {
  USER: '/api/user',
  USER_EMAIL: '/api/user/email',
  USER_EMAIL_VERIFY: '/api/user/email/verify',
  USER_PASSWORD: '/api/user/password',
  USER_ENRICH: '/api/hooks/enrich-user',
  SIGN_UP_API: '/api/sign-up',
};

export const images = {
  SCENE_EMAIL: '/images/auth/scene-email.png',
};

export const withReturnUrl = (url: string, returnUrl?: string) => {
  if (!returnUrl) {
    return url;
  }
  return url.includes('?') ? `${url}&returnUrl=${returnUrl}` : `${url}?returnUrl=${returnUrl}`;
};
