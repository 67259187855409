import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth, useFeatureFlags } from 'context';
import { pages, withReturnUrl } from 'features/auth/urls';
import logger from 'logging';

export type PagePrerequisitesProps = {
  requireGroups?: string[];
  requireAuthenticated?: boolean;
  requireFeatureFlag?: string;
};

export const PagePrerequisites: FC<PagePrerequisitesProps> = ({
  requireGroups,
  requireAuthenticated,
  requireFeatureFlag,
  children,
}) => {
  const { user, isUserInitialised } = useAuth();
  const router = useRouter();
  const [isPermitted, setIsPermitted] = useState(false);
  const features = useFeatureFlags();

  useEffect(() => {
    if (!router.isReady || !isUserInitialised) {
      return;
    }

    if (requireFeatureFlag && !features.initialised) {
      return;
    }

    if (requireAuthenticated || requireGroups) {
      if (user) {
        if (requireGroups) {
          const permitted = requireGroups.filter((x) => user.groups.some((z) => z === x)).length > 0;
          if (!permitted) {
            logger.debug('Required user group absent');
            router.push(withReturnUrl(pages.NOT_FOUND, router.asPath));
            return;
          }
        }
      } else {
        logger.debug('User required and not set');
        router.push(withReturnUrl(pages.LOG_IN_PAGE, router.asPath));
        return;
      }
    }

    if (requireFeatureFlag) {
      if (!features[requireFeatureFlag]) {
        logger.debug('Feature flag not set');
        router.push(withReturnUrl(pages.NOT_FOUND, router.asPath));
        return;
      }
    }

    setIsPermitted(true);
  }, [
    user,
    router,
    router.isReady,
    isUserInitialised,
    requireGroups,
    requireAuthenticated,
    requireFeatureFlag,
    features.initialised,
    features,
  ]);

  if (requireAuthenticated || requireGroups || requireFeatureFlag) {
    if (isPermitted) {
      return <>{children}</>;
    } else {
      return null;
    }
  } else {
    return <>{children}</>;
  }
};
