import { useMemo } from 'react';
import { StructuredTextDocument } from 'react-datocms';
import { GenericPage } from './types';

export function getPageInfo(page: GenericPage) {
  if (!page?.pageInfo || page.pageInfo.length === 0) {
    return null;
  }

  return page.pageInfo[0];
}

export const usePageInfo = (page: GenericPage) => {
  return useMemo(() => getPageInfo(page), [page]);
};

export const isStructuredTextEmpty = (document: StructuredTextDocument) => {
  if (!document?.document?.children || document?.document?.children.length === 0) {
    return true;
  }

  const child = document.document.children[0];
  if (
    !child ||
    (child.type === 'paragraph' &&
      child.children?.length > 0 &&
      child.children[0]?.type === 'span' &&
      child.children[0]?.value?.length === 0)
  ) {
    return true;
  }
  return false;
};
