import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { AuthContext } from 'context';
import { useAnalytics } from 'utils';
import { Colors, Theme } from 'components/Layout/theme';
import { CssBaseline, NoSsr } from '@material-ui/core';
import { SWRConfig, SWRConfiguration } from 'swr';
import { useRouter } from 'next/router';
import { pages, withReturnUrl } from 'features/auth/urls';
import { AxiosError } from 'axios';
import { MetaData, PageOptions, PagePrerequisites, TruePage } from 'components';
import { FeatureFlagsProvider } from 'context';
import { useCallback } from 'react';
import { mergeDeepLeft } from 'ramda';
import { DeepPartial } from 'utils/deep-partial';
import { GenericPage, PartnerBranding, usePageInfo } from 'services/cms';
import { useAttribution } from 'features/attribution/hooks/use-attribution';
import logger from 'logging';

const SetExpertOnlineAfterLogIn = dynamic(
  () => import('features/expert-availability/components/set-expert-online-after-log-in')
);

const LOGIN_REDIRECT_ERROR_STATUSES = [401];
const FAVICON_URL = 'https://www.datocms-assets.com/47922/1623033260-true-favicon.png';

type Props = AppProps & {
  Component: TruePage;
};

type PageProps = {
  // by convention, our CMS driven pages return a property "page" that contains some generic info (title, footer, seo etc)
  page?: GenericPage;
  // by convention, if a page returns a "partnerBranding" property, then we opt in to display the branding from cms
  partnerBranding?: PartnerBranding;
};

function App(props: Props) {
  useAnalytics();
  useAttribution();
  const { Component, pageProps }: { Component: TruePage; pageProps: PageProps } = props;
  const PageComponent: TruePage = Component;
  const [overrideOptions, setOverrideOptions] = useState(null);
  const [baseOptions, setBaseOptions] = useState<unknown>(null);

  const router = useRouter();

  const pageOptions = PageComponent.options;
  if (!pageOptions) {
    throw new Error('The page does not inherit from TruePage or options not set');
  }

  const options = pageOptions === baseOptions ? overrideOptions ?? pageOptions : pageOptions;

  const setOptions = useCallback(
    (patch: DeepPartial<PageOptions>) => {
      const patched = mergeDeepLeft(patch, pageOptions || {});
      setOverrideOptions(patched);
      setBaseOptions(pageOptions);
    },
    [pageOptions]
  );

  const swrConfig: SWRConfiguration = {
    onError: async (error: AxiosError) => {
      if (error.response?.status && LOGIN_REDIRECT_ERROR_STATUSES.includes(error.response.status)) {
        router.push(withReturnUrl(pages.LOG_OUT_PAGE, router.asPath));
      }
      if (error.response?.status >= 500) {
        logger.error(error.response.data);
      }
    },
  };

  useEffect(() => {
    if (options.headerColor === 'sponge-cake') {
      document.documentElement.style.backgroundColor = Colors.SpongeCake;
    } else if (options.headerColor === 'blue') {
      document.documentElement.style.backgroundColor = Colors.Blue;
    } else if (options.headerColor === 'pavlova') {
      document.documentElement.style.backgroundColor = Colors.Pavlova;
    } else if (options.headerColor === 'vegemite') {
      document.documentElement.style.backgroundColor = Colors.Vegemite;
    } else {
      document.documentElement.style.backgroundColor = Colors.Background;
    }
  }, [options.headerColor]);

  const pageInfo = usePageInfo(pageProps.page);
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />

        <meta
          name="viewport"
          key="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        {[16, 32, 96, 192].map((size) => (
          <link
            rel="shortcut icon"
            sizes={`${size}x${size}`}
            href={`${FAVICON_URL}?w=${size}&h=${size}`}
            type="image/png"
            key={size}
          />
        ))}
        <link rel="apple-touch-icon" sizes="192x192" href={`${FAVICON_URL}?w=192`} />
        {options.title && <title key="document-title">{options.title}</title>}
        {/* OG defaults */}
        <meta key="og:locale" property="og:locale" content="en" />
        <meta key="og:title" property="og:title" content="Real rates. Unreal savings by True" />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:site_name" property="og:site_name" content="truesavings.com" />
        <meta
          key="og:description"
          property="og:description"
          content="Stop paying the loyalty tax. We can save you thousands every year by helping you renegotiate your current loan or switch to a better deal."
        />
        <meta key="og:image" property="og:image" content="https://www.datocms-assets.com/47922/1625629791-trueog.jpg" />
        <meta key="og:image:width" property="og:image:width" content="1200" />
        <meta key="og:image:height" property="og:image:height" content="630" />

        <meta key="twitter:title" name="twitter:title" content="Take control of your home loan by True Savings" />
        <meta
          key="twitter:description"
          name="twitter:description"
          content="Stop paying the loyalty tax. We can save you thousands every year by helping you renegotiate your current loan or switch to a better deal."
        />
        <meta
          key="twitter:image"
          name="twitter:image"
          content="https://www.datocms-assets.com/47922/1625629791-trueog.jpg"
        />
        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
        <meta key="twitter:site" name="twitter:site" content="@truesavings_au" />
        {pageInfo?.seo && <MetaData excludeHead tags={pageInfo.seo} />}
      </Head>
      <Theme>
        <CssBaseline>
          <NoSsr>
            <AuthContext>
              <FeatureFlagsProvider>
                <SWRConfig value={swrConfig}>
                  <PagePrerequisites
                    requireAuthenticated={options.requireAuthenticated}
                    requireGroups={options.requireGroups}
                    requireFeatureFlag={options.requireFeatureFlag}
                  >
                    <SetExpertOnlineAfterLogIn />
                    {options.layout ? (
                      <options.layout options={options} partnerBranding={pageProps.partnerBranding}>
                        <PageComponent setOptions={setOptions} {...pageProps} />
                      </options.layout>
                    ) : (
                      <PageComponent setOptions={setOptions} {...pageProps} />
                    )}
                  </PagePrerequisites>
                </SWRConfig>
              </FeatureFlagsProvider>
            </AuthContext>
          </NoSsr>
        </CssBaseline>
      </Theme>
    </>
  );
}

export default App;
