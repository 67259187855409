import * as dayjs from '../../node_modules/dayjs';
import timezone from '../../node_modules/dayjs/plugin/timezone';
import utc from '../../node_modules/dayjs/plugin/utc';
import advancedFormat from '../../node_modules/dayjs/plugin/advancedFormat';

let modified = dayjs.extend(utc);
modified = dayjs.extend(timezone);
modified = dayjs.extend(advancedFormat);

dayjs.tz?.setDefault('Australia/Sydney');

export default modified;
