import useSWR from 'swr';
import { graphAPI } from 'services/cms/fetcher';
import { createContext, FC, useContext, useRef, useState } from 'react';
import { getEnvName } from 'utils/get-env-name';
import { useAuth } from 'context';
import { useEffect } from 'react';
import { equals } from 'ramda';
import { logError } from 'logging';

type FeatureFlags = {
  canvas?: boolean;
  canvasMobile?: boolean;
  canvasAnonymousCapturedetails?: boolean;
  canvasStructuredData?: boolean;
  homeArticleCarousel?: boolean;
  canvasLobbyTimeoutSecs?: number;
  environmentKey: string;
  initialised: boolean;
  referrals?: boolean;
  referralsListView?: boolean;
  referralsCheckoutView?: boolean;
  onDemandPopUp?: boolean;
};

export const useFeatureFlags = () => useContext<FeatureFlags>(FeatureFlagsContext);

const FeatureFlagsContext = createContext<FeatureFlags>(null);

export const FeatureFlagsProvider: FC = ({ children }) => {
  const { flags } = useFeatureFlagsData();
  const previousFlags = useRef<FeatureFlags>({} as FeatureFlags);
  const returnValue = equals(previousFlags.current, flags) ? previousFlags.current : flags;
  previousFlags.current = returnValue;

  return <FeatureFlagsContext.Provider value={returnValue}>{children}</FeatureFlagsContext.Provider>;
};

export type FeatureFlagKeys = keyof Omit<FeatureFlags, 'initialised' | 'environmentKey'>;

type FeatureFlagData = {
  allFeatureFlagEnvironmentals: FeatureFlags[];
};
const useFeatureFlagsData = () => {
  const { user, isUserInitialised } = useAuth();
  const environmentName = getEnvName();
  const userId = user?.id;
  const [flags, setFlags] = useState<FeatureFlags>({ initialised: false, environmentKey: '' });

  const query = `query MyQuery {
  allFeatureFlagEnvironmentals(filter: {environmentKey: {in: ["${environmentName}", "${userId}"]}}) {
    canvas
    canvasMobile
    canvasAnonymousCapturedetails
    canvasLobbyTimeoutSecs
    canvasStructuredData
    environmentKey
    homeArticleCarousel
    referrals
    referralsListView
    referralsCheckoutView
    onDemandPopUp
  }
}`;

  const { data, error, mutate } = useSWR<FeatureFlagData>(query, graphAPI, {
    refreshInterval: 1000 * 60 * 5, // five minutes
  });

  useEffect(() => {
    //if theres an error log & fail with all feature flags turned off
    if (error) logError(error);
  }, [error]);

  useEffect(() => {
    let result: FeatureFlags;
    if (data?.allFeatureFlagEnvironmentals?.length > 0) {
      const records = data.allFeatureFlagEnvironmentals.filter((e) => e.environmentKey === userId);
      if (records.length > 0) {
        result = records[0];
      } else {
        result = data.allFeatureFlagEnvironmentals[0];
      }
    }

    if (result) {
      //only fully initialised once we've got the user and made sure there are no user specific flags
      result.initialised = isUserInitialised;
    } else if (error) {
      result = { initialised: true, environmentKey: 'fallback' };
    } else {
      result = { initialised: false, environmentKey: '' };
    }
    setFlags(result);
  }, [data, error, isUserInitialised, userId]);

  return { flags, error, isLoading: !error && !data, mutate };
};
