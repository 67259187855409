import getConfig from 'config';

type EnvName = 'test' | 'development' | 'production';

export const getEnvName = (): EnvName => {
  const { NEXT_PUBLIC_ENVIRONMENT: raw } = getConfig();
  if (!raw) {
    throw new Error('Environment name not set');
  }
  const env = window.location.port === '3000' ? 'development' : raw.toLowerCase();
  if (!['test', 'development', 'production'].includes(env)) {
    throw new Error('Invalid environment name: ' + env);
  }
  return env as EnvName;
};
